import { UpdateParams } from "ra-core";
import { update } from "..";
import { ClientEmployerDTO } from "../../types/clientEmployer";

async function updateEmployer({ data }: UpdateParams<ClientEmployerDTO["data"]>) {
  const { id, attributes } = data as ClientEmployerDTO["data"];
  const updateEmployersEndpoint = `ms/employers/${id}`;
  const { name, code, clientId, clinicalProductIds } = attributes;

  const updateEmployerBody = {
    data: {
      type: "employers",
      id,
      attributes: {
        name,
        clientId,
        code,
      },
      relationships: {
        clinicalProducts: {
          data: clinicalProductIds.map(clinicalProductId => ({
            id: clinicalProductId
          })),
        }
      }
    },
  };

  const employerRes = await update(updateEmployersEndpoint, updateEmployerBody);

  return employerRes;
}

export default updateEmployer;
