import { Box } from "@mui/material";
import React from "react";
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  Pagination,
} from "react-admin";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";

export function ListExistingIdentifiers() {
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);

  return (
    <Box>
      <ReferenceManyField
        reference="clientIdentifiers"
        target="client.id"
        label={false}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField
            label="Client Identifier"
            source="attributes.clientIdentifier"
            sortable={false}
          />
          <TextField
            label="Name"
            source="attributes.name"
            sortable={false}
          />
          {
            clinicalProductsEnabled &&
            <TextField
            label="Product code"
            source="attributes.productCode"
            sortable={false}
          />}
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
}
