import React from "react";

import { styled } from "@mui/material/styles";

import {
  ArrayField,
  Datagrid,
  FunctionField,
  Pagination,
  RaRecord,
  ReferenceManyField,
  Show,
  TextField,
  Title,
  useRecordContext
} from "react-admin";
import { Actions } from "../Actions";
import { FullNameField } from "../fields/FullNameField";
import { ShowTermDetails } from "./ShowTermDetails";
import { ShowInstructions } from "./ShowInstructions";
import { PMSShowTab } from "./tabs/PMSShowTab";
import { ValidationShowTab } from "./tabs/ValidationShowTab";
import { ServiceConfigShowTab } from "./tabs/ServiceConfigShowTab";
import { ScrollableTabbedShowLayout } from "../ScrollableTabLayouts";
import { InstructionAside } from "../InstructionAside";
import { Tab } from "../Tabs";
import { ContractShowTab } from "./tabs/ContractShowTab";
import { CapsDelimitedField } from "../fields/CapsDelimitedField";
import { customStyles } from "../../theme";
import { NotificationShowTab } from "./tabs/NotificationShowTab";
import { BooleanField } from "../fields/BooleanField";
import { CPQualificationField } from "./CPQualificationField";
import DateField from "../fields/DateField";
import { useParams } from "react-router-dom";
import { CLIENT_LIVE_ID, NONE_SPECIFIED } from "../../constants/constants";
import { Typography } from "@mui/material";
import { EmployersShowTab } from "./tabs/EmployersShowTab";
import getClientBookingChannels from "./helpers/getClientBookingChannels";
import { useFlag } from "../../featureFlags";
import { TOGGLE_ENABLE_CLINICAL_PRODUCTS, TOGGLE_KYC, TOGGLE_OTP } from "../../featureFlags/flags";
import { ClientClinicianPoolsShowTab } from "./tabs/ClientClinicianPoolsShowTab";
import { countrySelectChoices } from "../../data/countryCodes";
import { ClinicalProductsShowTab } from "./tabs/ClinicalProductsShowTab";

const PREFIX = "ClientShow";

const classes = {
  datagridContainer: `${PREFIX}-datagridContainer`,
  root: `${PREFIX}-root`,
};

const StyledShow = styled(Show)({
  [`& .${classes.datagridContainer}`]: {
    ["& > div"]: {
      width: "100%",
    },
  },
  [`& .${classes.root}`]: {
    ...customStyles.horiztonalScrollableShadows,
  },
});

function PageTitle() {
  const record = useRecordContext();
  if (!record) return null;

  const { name } = record.attributes;

  return <Title title={name} />;
}

export function ClientShow() {
  const { id: clientId } = useParams();

  const isKycEnabled = useFlag(TOGGLE_KYC);
  const isOtpEnabled = useFlag(TOGGLE_OTP);
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);

  return (
    <StyledShow
      actions={<Actions />}
      title={<PageTitle />}
      aside={<InstructionAside source="id" />}
    >
      <ScrollableTabbedShowLayout>
        <Tab label="General">
          <TextField label="Contract Name" source="attributes.name" />
          <FunctionField
            label="Is Discarded"
            source="attributes.clientStatusID"
            render={(record: RaRecord) => {
              const clientStatusID = record?.attributes?.clientStatusID;

              if (clientStatusID === CLIENT_LIVE_ID) return "No";

              return "Yes";
            }}
          />
          <FunctionField
            label="Country"
            render={(record: RaRecord) =>
              countrySelectChoices.find(c => c.id === record.attributes.countryCode)?.name
            }
          />

          <TextField
            label="Master Client Name"
            source="masterClientGroup.clientGroups[0].attributes.name"
            emptyText="n/a"
          />
          <TextField label="ID" source="id" />

          { isKycEnabled && 
            <BooleanField
              label="Is KYC Enabled"
              source="attributes.isKycEnabled"
              emptyText="No"
            />
          }

          { isOtpEnabled && 
            <BooleanField
              label="Is OTP Enabled"
              source="attributes.isOtpEnabled"
              emptyText="No"
            />
          }

          <BooleanField
            label="Symptom Signposting Enabled"
            source="attributes.symptomSignpostingEnabled"
            emptyText="No"
          />
          
          <FunctionField
            label="Booking Channels"
            source="attributes.clientStatusID"
            render={(record: RaRecord) =>
              getClientBookingChannels(record, "clientBookingChannels.attributes")}
          />

          <CPQualificationField label="Required Qualifications" />
          <ArrayField source="followUpActions">
            <Datagrid
              bulkActionButtons={false}
              empty={<Typography variant="body1">{NONE_SPECIFIED}</Typography>}
            >
              <CapsDelimitedField
                sortable={false}
                label="Specialty"
                hideLabel
                source="attributes.specialty"
              />
              <CapsDelimitedField
                sortable={false}
                label="Output Type"
                hideLabel
                source="attributes.followUpActionType"
              />
            </Datagrid>
          </ArrayField>

          <ArrayField
            className={classes.datagridContainer}
            source="terms"
            label="Terms"
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick="expand"
              expand={<ShowTermDetails />}
              empty={<Typography variant="body1">{NONE_SPECIFIED}</Typography>}
            >
              <TextField source="id" label="Term ID" sortable={false} />
              <TextField
                source="attributes.title"
                label="Title"
                sortable={false}
              />
              <TextField
                source="attributes.version"
                label="Version"
                sortable={false}
              />
              <TextField
                source="attributes.type"
                label="Type"
                sortable={false}
              />
              <DateField
                source="attributes.activeFrom"
                label="Active from"
                hideLabel
                sortable={false}
              />
            </Datagrid>
          </ArrayField>

          <ArrayField
            className={classes.datagridContainer}
            source="instructions"
            label="Client Instructions"
          >
            <Datagrid
              rowClick="expand"
              expand={<ShowInstructions />}
              bulkActionButtons={false}
              empty={<Typography variant="body1">{NONE_SPECIFIED}</Typography>}
            >
              <TextField source="id" label="Instruction ID" />
              <TextField source="attributes.instructionType" label="Type" />
              <BooleanField source="attributes.isEnabled" label="Is Enabled" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <ContractShowTab />
        <Tab label="Bookings">
          <ReferenceManyField
            className={classes.root}
            reference="bookings"
            target="client.id"
            label={false}
            pagination={<Pagination />}
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={(id, basePath, record) => {
                const bookingUserId = record.users[0].id;

                return `/patientRecords/${bookingUserId}/clients/${clientId}/bookings/${record.id}/summary`;
              }}
            >
              <TextField label="Booking ID" source="id" />
              <FullNameField
                sortable={false}
                label="Patient name"
                firstNameSource="patients[0].attributes.firstName"
                lastNameSource="patients[0].attributes.lastName"
              />
              <FullNameField
                sortable={false}
                label="Clinical Practitioner name"
                firstNameSource="appointments[0].clinicalPractitioners[0].attributes.firstName"
                lastNameSource="appointments[0].clinicalPractitioners[0].attributes.lastName"
              />
              <CapsDelimitedField
                sortable={false}
                hideLabel
                label="Status"
                source="attributes.status"
              />
              <DateField
                label="Time"
                hideLabel
                sortBy="appointment.start"
                source="appointments[0].attributes.start"
                showTime
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <ServiceConfigShowTab />
        <ClientClinicianPoolsShowTab />
        <NotificationShowTab />
        <ValidationShowTab />
        {clinicalProductsEnabled && <ClinicalProductsShowTab />}
        <PMSShowTab />
        <EmployersShowTab />
      </ScrollableTabbedShowLayout>
    </StyledShow>
  );
}
