import React from "react";
import { ShowTabProps } from "../../../types/utilities";
import { Tab } from "../../Tabs";
import { Datagrid, ListContextProvider, useGetList, useList } from "react-admin";
import { useParams } from "react-router-dom";
import { FunctionField, TextField } from "ra-ui-materialui";
import { Typography } from "@mui/material";


export function ClinicalProductsShowTab(props: ShowTabProps) {
  const { id: clientId } = useParams();

  const {data, isLoading } = useGetList('clinicalProducts', {
    filter: {
      clientId
    },
    meta: {
      includes: ['clientIdentifiers,employers']
    }
  })

  const listContext = useList({data, isLoading})

  return (
    <Tab label={"Clinical Products"} {...props}>
      <ListContextProvider value={listContext}>
        <Datagrid
          bulkActionButtons={false}
        >
          <TextField label={"Clinical Product"} source={"attributes.name"}/>
          <FunctionField label={"Client Identifiers"} render={(record: any) => {
            return record.clientIdentifiers?.map((r: any) => <Typography key={r.id}>{r.attributes?.clientIdentifier}</Typography>);
          }}/>
          <FunctionField label={"Employers"} render={(record: any) => {
            return record.employers?.map((r: any) => <Typography key={r.id}>{r.attributes?.name}</Typography>);
          }}/>
        </Datagrid>
      </ListContextProvider>
    </Tab>);
}
