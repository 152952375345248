import { GetOneParams } from "react-admin";
import { get } from "../index";
import { ClinicalProductsDTO } from "../../types/clinicalProducts";
import { splitByCapitalLetters } from "../../utilities/splitByCapitalLetters";


const includes = [
  "clinicalServices",
  "clients"
];

async function getClinicalProduct(args: GetOneParams) {
  const { id } = args;

  const endpoint = `/ms/clinicalProducts/${id}`;

  const params = {
    include: includes.join(",")
  };

  const res = await get(endpoint, params);

  const data = Array.isArray(res.data)
    ? res.data[0]
    : res.data;

  const formatted = getFormattedClinicalProduct(data);

  return { id: data.id, data: formatted };
}

function getFormattedClinicalProduct(data: ClinicalProductsDTO["data"]) {

  const clinicalServices = data.clinicalServices ?? [];

  const services = [
    ...data.attributes.specialties.map(specialty => ({id: specialty, name: splitByCapitalLetters(specialty), group: "Specialties"})),
    ...clinicalServices.map((x: any) => ({ id: x.id, name: x.attributes.name, group: "Clinical Services" }))
  ];

  return {
    ...data,
    attributes: {
      ...data.attributes,
      services
    },
    servicesIds: services.map(x => x.id)
  }
}

export default getClinicalProduct;