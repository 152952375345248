import { ClinicalProductsDTO } from "../../types/clinicalProducts";
import { create } from "../index";

const endpoint = "/ms/clinicalProducts";

async function createClinicalProduct(args: any) {

  const { servicesIds } = args;
  const specialties = servicesIds.filter((service: any) => isNaN(service));
  const clinicalServices = servicesIds.filter((service: any) => !isNaN(service)).map((service: any) => ({id: service}));

  const body = {
    data: {
      type: "clinicalProducts",
      attributes: {
        ...args.attributes,
        specialties
      },
      relationships: {
        clinicalServices: {
          data: clinicalServices
        }
      }
    }
  } as ClinicalProductsDTO;


  return await create(endpoint, body);
}

export default createClinicalProduct;