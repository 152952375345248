import { update } from "../index";


async function updateClinicalProduct(args: any) {
  const { id, type, servicesIds } = args.data;
  const { name, code, isEnabled } = args.data.attributes;

  const specialties = servicesIds.filter((service: any) => isNaN(service));
  const clinicalServices = servicesIds.filter((service: any) => !isNaN(service)).map((service: any) => ({ id: service }));


  const endpoint = `/ms/clinicalProducts/${id}`;

  const body = {
    data: {
      id,
      type,
      attributes: {
        name,
        code,
        isEnabled,
        specialties
      },
      relationships: {
        clinicalServices: {
          data: clinicalServices
        }
      }
    }
  };


  return await update(endpoint, body);
}

export default updateClinicalProduct;