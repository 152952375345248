export type AppConfig = {
  api: {
    apiHost: string;
    apiVersion: string;
  };
  gaia: {
    apiHost: string;
  };
  auth: {
    clientId: string;
    clientSecret: string;
    authHost: string;
  };
  pew: {
    apiHost: string;
    apiVersion: string;
  };
  formFillPrescriptions: {
    clientIds: string[];
  };
  featureFlags: {
    useGaiaForCpDocuments: boolean;
  };
  dataDog: {
    site: string;
    host: string;
    service: string;
    environment: string;
  };
};

type ValidEnvironments = "development" | "uat" | "prod" | "local" | "sandbox";

const defaultEnvironment = "development" as ValidEnvironments;

const config: Record<ValidEnvironments, AppConfig> = {
  local: {
    api: {
      apiHost: "http://localhost:5000/api",
      apiVersion: "1",
    },
    gaia: {
      apiHost:
        "https://dev-api-integration.healthherotechnologies.co.uk/ed3compositor/api",
    },
    auth: {
      clientId: "ED3",
      clientSecret: "MSAPISecret",
      authHost: "https://dev-api.healthhero.com/api",
    },
    pew: {
      apiHost: "https://localhost:5001/api",
      apiVersion: "1",
    },
    formFillPrescriptions: {
      clientIds: ["10980", "11000"],
    },
    featureFlags: {
      useGaiaForCpDocuments: false,
    },
    dataDog: {
      site: "datadoghq.eu",
      host: "ed3-web",
      service: "ed3",
      environment: "local",
    },
  },
  development: {
    api: {
      apiHost: "https://dev-api.healthhero.com/api",
      apiVersion: "1",
    },
    gaia: {
      apiHost:
        "https://dev-api-integration.healthherotechnologies.co.uk/ed3compositor/api",
    },
    auth: {
      authHost: "https://dev-api.healthhero.com/api",
      clientId: "ED3",
      clientSecret: "MSAPISecret",
    },
    pew: {
      apiHost: "https://ms-dev-pew-cmp-app.azurewebsites.net/api",
      apiVersion: "1",
    },
    formFillPrescriptions: {
      clientIds: ["11516", "12701"],
    },
    featureFlags: {
      useGaiaForCpDocuments: false,
    },
    dataDog: {
      site: "datadoghq.eu",
      host: "ed3-web",
      service: "ed3",
      environment: "dev",
    },
  },
  uat: {
    api: {
      apiHost: "https://uat-api.healthhero.com/api",
      apiVersion: "1",
    },
    gaia: {
      apiHost:
        "https://uat-api-integration.healthherotechnologies.co.uk/ed3compositor/api",
    },
    auth: {
      clientId: "ED3",
      clientSecret: "MSAPISecret",
      authHost: "https://uat-api.healthhero.com/api",
    },
    pew: {
      // apiHost: "https://ms-uat-pew-cmp-app.azurewebsites.net/api",
      // TODO: currently dev PEW because it points to UAT SAPI
      apiHost: "https://ms-dev-pew-cmp-app.azurewebsites.net/api",
      apiVersion: "1",
    },
    formFillPrescriptions: {
      clientIds: ["10980", "11000"],
    },
    featureFlags: {
      useGaiaForCpDocuments: true,
    },
    dataDog: {
      site: "datadoghq.eu",
      host: "ed3-web",
      service: "ed3",
      environment: "uat",
    },
  },
  prod: {
    api: {
      apiHost: "https://api.healthhero.com/api",
      apiVersion: "1",
    },
    gaia: {
      apiHost:
        "https://api-integration.healthherotechnologies.co.uk/ed3compositor/api",
    },
    auth: {
      clientId: "ED3",
      clientSecret: "MSAPISecret",
      authHost: "https://api.healthhero.com/api",
    },
    pew: {
      apiHost: "https://ms-prod-pew-cmp-app.azurewebsites.net/api",
      apiVersion: "1",
    },
    formFillPrescriptions: {
      clientIds: ["1004", "1006"],
    },
    featureFlags: {
      useGaiaForCpDocuments: true,
    },
    dataDog: {
      site: "datadoghq.eu",
      host: "ed3-web",
      service: "ed3",
      environment: "prod",
    },
  },
  sandbox: {
    api: {
      apiHost: "https://sandbox-msapi.azurewebsites.net/api",
      apiVersion: "1",
    },
    gaia: {
      apiHost:
        "https://dev-api-integration.healthherotechnologies.co.uk/ed3compositor/api",
    },
    auth: {
      clientId: "ED3",
      clientSecret: "MSAPISecret",
      authHost: "https://sandbox-msapi.azurewebsites.net/api",
    },
    pew: {
      apiHost: "",
      apiVersion: "",
    },
    formFillPrescriptions: {
      clientIds: ["11516"],
    },
    featureFlags: {
      useGaiaForCpDocuments: false,
    },
    dataDog: {
      site: "datadoghq.eu",
      host: "ed3-web",
      service: "ed3",
      environment: "sandbox",
    },
  },
};

export function getConfig() {
  let env = defaultEnvironment;

  if (process.env.REACT_APP_ENV) {
    env = process.env.REACT_APP_ENV;
  } else if (isTesting()) {
    env = getTestTargetEnvironment();
  }

  return config[env];
}

function isTesting() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return typeof Cypress !== "undefined";
  } catch {
    return false;
  }
}

function getTestTargetEnvironment(): ValidEnvironments {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Cypress.env("app_env");
  } catch {
    return defaultEnvironment;
  }
}
