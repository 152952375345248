import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Form, maxLength, required } from "react-admin";
import { AutocompleteArrayInput, ReferenceArrayInput, TextInput } from "ra-ui-materialui";
import { FieldValues, useWatch } from "react-hook-form";
import { FormDataConsumer } from "ra-core";
import { areArraysEqual } from "../../../utilities/areArraysEqual";
import { useFlag } from "../../../featureFlags";
import { TOGGLE_ENABLE_CLINICAL_PRODUCTS } from "../../../featureFlags/flags";

interface EmployerRecord {
  name: string;
  code: string;
  clinicalProductIds: Array<string>;
}

export const BaseEmployerDialog = (props: {
  isOpened: boolean;
  onClose: () => void;
  title: string;
  submitButtonText: string;
  record: EmployerRecord;
  submit: (values: FieldValues) => void;
}) => {
  const { isOpened, onClose, title, submitButtonText, record, submit } = props;
  const clinicalProductsEnabled = useFlag(TOGGLE_ENABLE_CLINICAL_PRODUCTS);

  const clientId = useWatch({name: "id"});

  return (
    <Dialog
      open={isOpened}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      data-cy="employer-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <Form record={record} onSubmit={submit}>
        <DialogContent>
          <TextInput
            label={"Name"}
            source="name"
            fullWidth
            validate={[required(), maxLength(512)]}
          />
          {clinicalProductsEnabled ?
            <ReferenceArrayInput
              source={"clinicalProductIds"}
              reference="clinicalProducts"
              filter={{ availableForClient: clientId }}
              required
            >
              <AutocompleteArrayInput
                id={"id"}
                sx={{ display: "inline-flex" }}
                label="Clinical Products"
                optionText="attributes.name"
                filterToQuery={query => ({
                  name: query,
                })}
              />
            </ReferenceArrayInput> : null}
        </DialogContent>
        <FormDataConsumer>
          {({ formData }) => {
            const isSubmitDisabled =  record.name == formData.name &&
              (!clinicalProductsEnabled || areArraysEqual(record.clinicalProductIds, formData.clinicalProductIds));

            return (<DialogActions>
              <Button onClick={onClose} aria-label="Cancel">
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={isSubmitDisabled} data-testid={"submitChangeEmployerButton"}>
                {submitButtonText}
              </Button>
            </DialogActions>);
          }}
        </FormDataConsumer>
      </Form>
    </Dialog>
  );
};
