import { create } from "..";
import { ClientEmployerDTO } from "../../types/clientEmployer";


async function createEmployer(body: ClientEmployerDTO["data"]) {
  const createEmployerEndpoint = "ms/employers";
  const { attributes } = body;
  const createEmployerBody = {
    data: {
      type: "employers",
      attributes: {
        name: attributes.name,
        code: attributes.code,
        clientId: attributes.clientId,
      },
      relationships: {
        clinicalProducts: {
          data: attributes.clinicalProductIds.map(clinicalProductId => ({
            id: clinicalProductId
          })),
        }
      }
    },
  };

  const employerRes = await create(createEmployerEndpoint, createEmployerBody);

  return employerRes;
}

export default createEmployer;
