import { GetListParams } from "ra-core";
import { list } from "../index";
import shallowlyRemoveEmptyValues from "../../utilities/shallowlyRemoveEmptyValue";

const endpoint = "/ms/clinicalProducts";

async function listClinicalProducts(params: GetListParams) {
  const { pagination, filter, meta } = params;
  const { page, perPage } = pagination;
  const { name, code, isEnabled, clientId, availableForClient } = filter;

  const filters = {
    "filter[name]": name ? `like:${name}` : "",
    "filter[code]": code ? `like:${code}` : "",
    "filter[isEnabled]": isEnabled,
    "filter[clients.id]": clientId,
    "availableForClient": availableForClient,
  };

  const filterParams = shallowlyRemoveEmptyValues(filters);


  const query = {
    "page[number]": page,
    "page[size]": perPage,
    "include": meta?.includes?.join(','),
    ...filterParams
  }


  const { data, total } = await list(endpoint, {
    params: query
  });


  return { data, total }
}

export default listClinicalProducts;
