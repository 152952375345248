import { DataProvider } from "react-admin";

import {
  createPatientRecord,
  getPatientRecord,
  listPatientRecords,
  updatePatientRecord,
  getManyPatientRecords,
  createDependantRecord,
  updateDependantRecord,
  getPatientTerms,
  linkPatientWithGuardian,
} from "./api/patientRecords";

import {
  createBooking,
  deleteBooking,
  getBooking,
  updateBooking,
  listBookings,
  rescheduleBooking,
  swapBooking,
  getBookingsByReference,
  getManyBookings,
} from "./api/bookings";

import {
  listClients,
  createClient,
  getClient,
  updateClient,
} from "./api/clients";

import {
  createClinicalPractitioner,
  getClinicalPractitioner,
  listClinicalPractitioners,
  updateClinicalPractitioner,
  getManyClinicalPractitioners,
  getExpertise,
  getManyExpertiseAreas,
} from "./api/clinicalPractitioners";

import {
  getWorkQueueItem,
  listWorkQueue,
  assignWorkItem,
  unassignWorkItem,
  completeWorkItem,
} from "./api/workQueues";

import {
  listResources,
  getResource,
  updateResource,
  createResource,
  getManyResources,
} from "./api/resources";

import {
  listAppointments,
  getAdminAppointment,
  getManyAdminAppointments,
  deleteAppointment,
  makeAppointmentAvailable,
  makeAppointmentNonWorking,
  convertToMAD,
  createAppointment,
  createAppointmentHold,
} from "./api/appointments";
import {
  getMessageThread,
  startMessageThread,
  closeMessageThread,
  sendMessage,
  getManyMessageThreads,
} from "./api/messageThreads";

import {
  listShifts,
  createShift,
  getShift,
  createAppointments,
  listProposedAppointments,
  deleteManyProposedAppointments,
  applyShiftOperation,
  importShifts,
} from "./api/shifts";

import {
  listQuinyxResources,
  getQuinyxResource,
  assignQuinyxResource,
} from "./api/quinyxResources";

import {
  listEmployers,
  getManyEmployers,
  createEmployer,
  getEmployer,
  deleteEmployer,
  deleteManyEmployers,
  updateEmployer,
  getClientEmployerSettings,
} from "./api/employers";

import {
  listHealthmailRecords,
  getHealthmailRecord,
  sendHealthmail,
  listGaiaPharmacies,
  getManyGaiaPharmacies,
} from "./api/healthmail";

import {
  listClientCodes,
  getManyClientCodes,
  createClientCode,
} from "./api/client-codes";

import {
  createResourcePool,
  getResourcePool,
  listResourcePools,
  updateResourcePool,
} from "./api/resourcePools";

import {
  listPharmacies,
  getPharmacy,
  createPharmacy,
  updatePharmacy,
  deletePharmacy,
} from "./api/pharmacies";

import {
  listGpSurgery,
  getGpSurgery,
  createGpSurgery,
  updateGpSurgery,
  deleteGpSurgery,
} from "./api/gpSurgeries";

import {
  createClinicalService,
  getClinicalService,
  updateClinicalService,
  listClinicalServices,
  getManyClinicalServices,
} from "./api/clinicalServices";

import {
  createClinicalProduct,
  getClinicalProduct,
  listClinicalProducts,
  updateClinicalProduct,
  getManyClinicalProducts,
} from "./api/clinicalProducts";

import { listDocuments, getDocument, downloadDocument, getManyEncounterConsultationOutputs } from "./api/documents";
import {
  getOrder,
  cancelOrder,
  refundOrder,
  captureOrderPayment,
} from "./api/orders";
import resetPassword from "./api/resetPassword";
import changePassword from "./api/changePassword";
import resendNotifications from "./api/resendNotifications";
import acceptTerm from "./api/acceptTerm";
import downloadFile from "./api/downloadFile";
import downloadUnencryptedOutput from "./api/downloadUnencryptedOutput";
import getCurrentUser from "./api/getCurrentUser";
import {
  listPatientManagementSystems,
  getPatientManagementSystem,
} from "./api/patientManagementSystems";
import { RealTimeDataProvider } from "./types/realTime";
import AdminHub from "./sockets/AdminHub";
import sendJoiningInformation from "./api/bookings/sendJoiningInformation";
import sendImageRequest from "./api/bookings/sendImageRequest";
import getSendgridDynamicTemplates from "./api/clients/getSendgridDynamicTemplates";
import getManySendgridDynamicTemplates from "./api/clients/getManySendgridDynamicTemplates";
import { listClientInstructions } from "./api/clients/listClientInstructions";
import getAppointmentFilters from "./api/clients/getAppointmentFilters";
import createMasterClient from "./api/clients/createMasterClient";
import getMasterClients from "./api/clients/getMasterClients";
import getManyMasterClients from "./api/clients/getManyMasterClients";
import { listAddressesByPostcode } from "./api/listAddressesByPostcode";
import getIdentifiers from "./api/clients/getIdentifiers";
import createIdentifiersDownload from "./api/clients/createIdentifiersDownload";
import updateAdastra from "./api/updateAdastra";
import { getGooglePlaceDetails, listGooglePlaces } from "./api/googlePlace";
import getManyClients from "./api/clients/getManyClients";
import listQuinyxUnits from "./api/quinyxUnits/listQuinyxUnits";
import releaseAppointmentHold from "./api/appointments/releaseAppointmentHold";
import updateMessageThread from "./api/messageThreads/updateMessageThread";
import getManyClientEmployers from "./api/clients/getManyClientEmployers";
import refundConsultation from "./api/refundConsultation";
import resendVerificationLink from "./api/patientRecords/resendVerificationLink";
import { listQuinyxShiftTypes } from "./api/quinyxShiftTypes";
import getFulfillmentDetails from "./api/healthmail/getFulfillmentDetails";
import listClinicalServiceWithSpeciality from "./api/clients/listClinicalServiceWithSpeciality";
import getManyClinicalServiceWithSpeciality from "./api/clients/getManyClinicalServiceWithSpeciality";
import importShiftsStart from "./api/shifts/importShiftsStart";
import importShiftsProcess from "./api/shifts/importShiftsProcess";
import getAvailableServices from "./api/clients/getAvailableServices";
import getPrescriptionsByReference from "./api/prescriptions/getPrescriptionsByReference";
import getOnePrescription from "./api/prescriptions/getOnePrescription";
import cancelShift from "./api/shifts/cancelShift";
import { getSignposting } from "./api/clients/getSignposting";

function devLogger<T>(method: string, resource: string, params: T) {
  if (process.env.NODE_ENV === "development") {
    console.log(`dataprovider - ${method}: ${resource}`);
    console.log(`-- ${JSON.stringify(params)} --`);
  }
}

type ExtendedDataProvider = DataProvider & RealTimeDataProvider;

const dataProvider: ExtendedDataProvider = {
  getList: async (resource, params) => {
    devLogger("getList", resource, params);

    if (resource === "clinicalServicesWithSpecialities") {
      return listClinicalServiceWithSpeciality(params);
    }

    if (resource === "patientRecords") {
      return listPatientRecords(params);
    }

    if (resource === "clients") {
      return listClients(params);
    }

    if (resource === "adminAppointments" || resource === "appointments") {
      return listAppointments(params, resource);
    }

    if (resource === "clinicalPractitioners") {
      return listClinicalPractitioners(params);
    }

    if (resource === "workQueues") {
      return listWorkQueue(params);
    }

    if (resource === "resourceManagement") {
      return listResources(params);
    }

    if (resource === "bookings") {
      return listBookings(params);
    }

    if (resource === "currentUser") {
      return getCurrentUser();
    }

    if (resource === "patientManagementSystems") {
      return listPatientManagementSystems();
    }

    if (resource === "expertiseAreas") {
      return getExpertise(params);
    }

    if (resource === "dynamicTemplates") {
      return getSendgridDynamicTemplates(params);
    }

    if (resource === "shifts") {
      return listShifts(params);
    }

    if (resource === "resourcePools") {
      return listResourcePools(params);
    }

    if (resource === "clinicalServices") {
      return listClinicalServices(params);
    }

    if (resource === "quinyxShiftTypes") {
      return listQuinyxShiftTypes();
    }

    if (resource === "quinyxUnits") {
      return listQuinyxUnits();
    }

    if (resource === "proposedAppointments") {
      return listProposedAppointments(params);
    }

    if (resource === "instructions") {
      return listClientInstructions(params);
    }

    if (resource === "clientGroups") {
      return getMasterClients(params);
    }

    if (resource === "quinyxResources") {
      return listQuinyxResources(params);
    }

    if (resource === "addresses") {
      return listAddressesByPostcode(params);
    }

    if (resource === "employers") {
      return listEmployers(params);
    }

    if (resource === "consultationOutputs") {
      return listDocuments(params);
    }

    if (resource === "healthmail") {
      return listHealthmailRecords(params);
    }

    if (resource === "googlePlaces") {
      return listGooglePlaces(params);
    }

    if (resource === "gaia:pharmacies") {
      return listGaiaPharmacies(params);
    }

    if (resource === "clientCodes") {
      return listClientCodes(params);
    }

    if (resource === "pharmacies") {
      return listPharmacies(params);
    }

    if (resource === "gpSurgeries") {
      return listGpSurgery(params);
    }

    if (resource === "clinicalProducts") {
      return listClinicalProducts(params);
    }

    return {
      data: [],
      total: 0,
    };
  },

  getOne: async (resource, params) => {
    devLogger("getOne", resource, params);

    if (resource === "gaia:fulfillmentDetails") {
      return getFulfillmentDetails(params);
    }

    if (resource === "patientRecords") {
      return getPatientRecord(params);
    }

    if (resource === "bookings") {
      return getBooking(params);
    }

    if (resource === "clients") {
      return getClient(params);
    }

    if (resource === "adminAppointments") {
      return getAdminAppointment(params);
    }

    if (resource === "clinicalPractitioners") {
      return getClinicalPractitioner(params);
    }

    if (resource === "workQueues") {
      return getWorkQueueItem(params);
    }

    if (resource === "resourceManagement") {
      return getResource(params);
    }

    if (resource === "messageThreads") {
      return getMessageThread(params);
    }

    if (resource === "shifts") {
      return getShift(params);
    }

    if (resource === "resourcePools") {
      return getResourcePool(params);
    }

    if (resource === "clinicalServices") {
      return getClinicalService(params);
    }

    if (resource === "appointmentFilters") {
      return getAppointmentFilters(params);
    }

    if (resource === "availableServices") {
      return getAvailableServices(params);
    }

    if (resource === "quinyxResources") {
      return getQuinyxResource(params);
    }

    if (resource === "employers") {
      return getEmployer(params);
    }

    if (resource === "consultationOutputs") {
      return getDocument(params);
    }

    if (resource === "healthmail") {
      return getHealthmailRecord(params);
    }

    if (resource === "googlePlaces") {
      return getGooglePlaceDetails(params);
    }

    if (resource === "patientManagementSystems") {
      return getPatientManagementSystem(params);
    }

    if (resource === "employerSettings") {
      return getClientEmployerSettings(params);
    }

    if (resource === "pharmacies") {
      return getPharmacy(params);
    }

    if (resource === "gpSurgeries") {
      return getGpSurgery(params);
    }

    if (resource === "prescriptions") {
      return getOnePrescription(params);
    }

    if (resource === "clinicalProducts") {
      return getClinicalProduct(params);
    }

    return Promise.resolve({ data: {} as any });
  },

  getMany: async (resource, params) => {
    devLogger("getMany", resource, params);

    if (resource === "clinicalServicesWithSpecialities") {
      return getManyClinicalServiceWithSpeciality(params);
    }

    if (resource === "clinicalServices") {
      return getManyClinicalServices(params);
    }

    if (resource === "patientRecords") {
      return getManyPatientRecords(params);
    }

    if (resource === "clinicalPractitioners") {
      return getManyClinicalPractitioners(params);
    }

    if (resource === "bookings") {
      return getManyBookings(params);
    }

    if (resource === "adminAppointments") {
      return getManyAdminAppointments(params);
    }

    if (resource === "dynamicTemplates") {
      return getManySendgridDynamicTemplates(params);
    }

    if (resource === "expertiseAreas") {
      return getManyExpertiseAreas(params);
    }

    if (resource === "clientGroups") {
      return getManyMasterClients(params);
    }

    if (resource === "orders") {
      return getOrder(params);
    }

    if (resource === "patientTerms") {
      return getPatientTerms(params);
    }

    if (resource === "clients") {
      return getManyClients(params);
    }

    if (resource === "employers") {
      return getManyEmployers(params);
    }

    if (resource === "resourceManagement") {
      return getManyResources(params);
    }

    if (resource === "gaia:pharmacies") {
      return getManyGaiaPharmacies(params);
    }

    if (resource === "clientCodes") {
      return getManyClientCodes(params);
    }

    if (resource === "quinyxShiftTypes") {
      return listQuinyxShiftTypes();
    }

    if (resource === "clinicalProducts") {
      return getManyClinicalProducts(params);
    }

    return Promise.resolve({ data: [] });
  },

  getManyReference: (resource, params) => {
    devLogger("getManyReference", resource, params);

    if (resource === "encounterConsultationOutputs") {
      return getManyEncounterConsultationOutputs(params);
    }

    if (resource === "bookings") {
      return getBookingsByReference(params);
    }

    if (resource === "clientIdentifiers") {
      return getIdentifiers(params);
    }

    if (resource === "messageThreads") {
      return getManyMessageThreads(params);
    }

    if (resource === "clientEmployers") {
      return getManyClientEmployers(params);
    }

    if (resource === "prescriptions") {
      return getPrescriptionsByReference(params);
    }

    if (resource === "symptomSignposting") {
      return getSignposting(params);
    }

    return Promise.resolve({ data: [], total: 0 });
  },

  update: (resource, params) => {
    devLogger("update", resource, params);

    const { data } = params;

    if (resource === "patientRecords") {
      if (data.attributes.isDependant) {
        return updateDependantRecord(params);
      }

      return updatePatientRecord(params);
    }

    if (resource === "bookings") {
      return updateBooking(params);
    }

    if (resource === "clients") {
      return updateClient(params);
    }

    if (resource === "term") {
      return acceptTerm(params);
    }

    if (resource === "clinicalPractitioners") {
      return updateClinicalPractitioner(params);
    }

    if (resource === "resourceManagement") {
      return updateResource(params);
    }

    if (resource === "messageThreads") {
      return updateMessageThread(params);
    }

    if (resource === "quinyxResources") {
      return assignQuinyxResource(params);
    }

    if (resource === "employers") {
      return updateEmployer(params);
    }

    if (resource === "resourcePools") {
      return updateResourcePool(params);
    }

    if (resource === "clinicalServices") {
      return updateClinicalService(params);
    }

    if (resource === "pharmacies") {
      return updatePharmacy(params);
    }

    if (resource === "gpSurgeries") {
      return updateGpSurgery(params);
    }

    if (resource === "clinicalProducts") {
      return updateClinicalProduct(params);
    }

    return Promise.resolve({ data: {} });
  },

  updateMany: (resource, params) => {
    devLogger("updateMany", resource, params);

    return Promise.resolve({ data: [] });
  },

  create: (resource, params) => {
    devLogger("create", resource, params);
    const { data } = params;

    if (resource === "importShiftsStarted") {
      return importShiftsStart(data);
    }

    if (resource === "importShiftsProcessed") {
      return importShiftsProcess(data);
    }

    if (resource === "importShiftsStarted") {
      return importShiftsStart(data);
    }

    if (resource === "importShiftsProcessed") {
      return importShiftsProcess(data);
    }

    if (resource === "patientRecords") {
      if (data.isDependant) {
        return createDependantRecord(data);
      }

      return createPatientRecord(data);
    }

    if (resource === "rescheduleBooking") {
      return rescheduleBooking(data);
    }

    if (resource === "swapBooking") {
      return swapBooking(data);
    }

    if (resource === "resendNotifications") {
      return resendNotifications(data);
    }

    if (resource === "sendJoiningInformation") {
      return sendJoiningInformation(data);
    }

    if (resource === "sendImageRequest") {
      return sendImageRequest(data);
    }

    if (resource === "resetPassword") {
      return resetPassword(data);
    }

    if (resource === "resendVerificationLink") {
      return resendVerificationLink(data);
    }

    if (resource === "changePassword") {
      return changePassword(data);
    }

    if (resource === "refundConsultation") {
      return refundConsultation(data);
    }

    if (resource === "refundOrder") {
      return refundOrder(data);
    }

    if (resource === "cancelOrder") {
      return cancelOrder(data);
    }

    if (resource === "captureOrderPayment") {
      return captureOrderPayment(data);
    }

    if (resource === "bookings") {
      if (data.consultationType === "MessageDoctor") {
        return startMessageThread(data);
      }

      return createBooking(data);
    }

    if (resource === "clients") {
      return createClient(data);
    }

    if (resource === "clinicalPractitioners") {
      return createClinicalPractitioner(data);
    }

    if (resource === "assignWorkItem") {
      return assignWorkItem(data);
    }

    if (resource === "unassignWorkItem") {
      return unassignWorkItem(data);
    }

    if (resource === "completeWorkItem") {
      return completeWorkItem(data);
    }

    if (resource === "downloadFile") {
      return downloadFile(data);
    }

    if (resource === "resourceManagement") {
      return createResource(data);
    }

    if (resource === "closeThread") {
      return closeMessageThread(data);
    }

    if (resource === "messages") {
      return sendMessage(data);
    }

    if (resource === "shifts") {
      return createShift(data);
    }

    if (resource === "resourcePools") {
      return createResourcePool(data);
    }

    if (resource === "clinicalServices") {
      return createClinicalService(data);
    }

    if (resource === "importedShifts") {
      return importShifts(data);
    }

    if (resource === "makeAppointmentAvailable") {
      return makeAppointmentAvailable(data);
    }

    if (resource === "makeAppointmentNonWorking") {
      return makeAppointmentNonWorking(data);
    }

    if (resource === "adminAppointments") {
      return createAppointment(data);
    }

    if (resource === "createAppointments") {
      return createAppointments(data);
    }

    if (resource === "applyShiftOperation") {
      return applyShiftOperation(data);
    }

    if (resource === "convertToMAD") {
      return convertToMAD(data);
    }

    if (resource === "downloadUnencryptedOutput") {
      return downloadUnencryptedOutput(data);
    }

    if (resource === "clientGroups") {
      return createMasterClient(data);
    }

    if (resource === "appointmentHolds") {
      return createAppointmentHold(data);
    }

    if (resource === "releaseAppointmentHolds") {
      return releaseAppointmentHold(data);
    }

    if (resource === "employers") {
      return createEmployer(data);
    }

    if (resource === "clientIdentifiers") {
      return createIdentifiersDownload(data);
    }

    if (resource === "addCase") {
      return updateAdastra(data);
    }

    if (resource === "gaia:downloadFile") {
      return downloadDocument(data);
    }

    if (resource === "gaia:sendHealthmail") {
      return sendHealthmail(data);
    }

    if (resource === "downloadClientIdentifiers") {
      return createIdentifiersDownload(data);
    }

    if (resource === "clientCodes") {
      return createClientCode(data);
    }

    if (resource === "linkWithGuardian") {
      return linkPatientWithGuardian(data);
    }

    if (resource === "pharmacies") {
      return createPharmacy(data);
    }

    if (resource === "gpSurgeries") {
      return createGpSurgery(data);
    }

    if (resource === "clinicalProducts") {
      return createClinicalProduct(data);
    }

    return Promise.resolve({ data: {} });
  },

  delete: (resource, params) => {
    devLogger("delete", resource, params);

    if (resource === "bookings") {
      return deleteBooking(params);
    }

    if (resource === "adminAppointments") {
      return deleteAppointment(params);
    }

    if (resource === "employers") {
      return deleteEmployer(params);
    }

    if (resource === "pharmacies") {
      return deletePharmacy(params);
    }

    if (resource === "gpSurgeries") {
      return deleteGpSurgery(params);
    }

    if (resource === "shifts") {
      return cancelShift(params);
    }

    return Promise.resolve({});
  },

  deleteMany: (resource, params) => {
    devLogger("deleteMany", resource, params);

    if (resource === "proposedAppointments") {
      return deleteManyProposedAppointments(params);
    }

    if (resource === "employers") {
      return deleteManyEmployers(params);
    }

    return Promise.resolve({ data: [] });
  },

  subscribe: async (topic, subscriptionCallback) => {
    const hub = AdminHub.getInstance();
    const state = hub.getConnectionState();

    if (state !== "Connected") return Promise.resolve({ data: null });

    hub.addEventListener(topic, subscriptionCallback);

    return Promise.resolve({ data: null });
  },
  unsubscribe: async (topic, subscriptionCallback) => {
    const hub = AdminHub.getInstance();

    hub.removeEventListener(topic, subscriptionCallback);

    return Promise.resolve({ data: null });
  },
  publish: async (topic, event) => {
    const { payload } = event;

    const hub = AdminHub.getInstance();
    const state = hub.getConnectionState();

    if (state !== "Connected") {
      return Promise.reject("We had trouble connecting to the server");
    }

    await hub.sendRequest(topic, ...payload);

    return Promise.resolve({ data: null });
  },
};

export default dataProvider;
