import { Relationship } from "./utilities";
import { ClinicalProductsDTO } from "./clinicalProducts";

type ClientEmployerRelationships = {
  clinicalProducts: { name: "clinicalProducts"; type: "many" };
};

export interface ClientEmployerDTO {
  data: {
    id: string;
    type?: "employers";
    attributes: {
      clientId: string;
      code: string;
      name: string;
      clinicalProductIds: Array<string>;
    };
    relationships: Relationship<ClientEmployerRelationships>;
    clinicalProducts: ClinicalProductsDTO["data"][];
  };
}

// eslint-disable-next-line no-shadow
export enum ChangeAction {"Added" = 0, "Edited" = 1, "Deleted" = 2}

export interface ClientEmployerChange {
  id: string,
  payload: ClientEmployerDTO["data"],
  oldPayload?: ClientEmployerDTO["data"],
  action: ChangeAction
}

